import React, { useEffect } from 'react';
import NextHead from 'next/head';
import { withRouter } from 'next/router';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import {
	affirmPublicApiKey,
	affirmScriptUrl,
	isEngage,
	isEngageTraining,
	monetateEnabled,
	monetateTagType,
	noRobots,
	rootRelativeMobileSitePath,
	s7ContentPath,
	s7ImagePath,
	sitePath,
} from '~/global/global.constants';
import { crazyS7ImageEscape } from '~/util/crazyS7ImageEscape';
import { VisitorEventModelFactory } from '~/tracking/visitor-event/Models/VisitorEvent.tracking.model';
import { SearchEventsTrackingStoreFactory } from '~/tracking/search/Stores/SearchEvents.tracking.store';
import { PageViewEvent } from '~/tracking/pageview-event/Component/PageViewEvent';

const Head = (props) => {
	const affirmEnabled = Boolean(affirmPublicApiKey) && Boolean(affirmScriptUrl);
	const {
		router,
		router: {
			asPath = '',
			events: routerEvents,
		} = {},
		additionalScriptTags,
		description = '',
		errorStatusCode,
		keywords = '',
		openGraphType = '',
		provideRelAlternate = false, // remove this when product pages no longer link to mobile versions of pages
		title = '',
		type = '',
		ogImage = '',
		url = '',
		canonicalUrl = '',
		noRobots: noRobotsProp = false,
	} = props;
	const {
		globalDynamicStore,
		featureTogglesModel,
		personalizationStore,
	} = useGlobalContext();
	const asPathWithoutParams = asPath.split('?')[0];

	const prefixedTitle = isEngage ? `Engage: ${title}` : title;

	const cleanImage = crazyS7ImageEscape(ogImage);

	/* eslint-disable prefer-const */
	let timestampForTracking;
	// PST for some reason
	const pacificTzOffset = -8;
	let currentYear;

	timestampForTracking = new Date();
	const tzOffset = (timestampForTracking.getTimezoneOffset() + (pacificTzOffset * 60)) * 60 * 1000;

	timestampForTracking.setTime(timestampForTracking.getTime() + tzOffset);
	currentYear = new Intl.DateTimeFormat('en-US', { year: 'numeric' })
		.format(timestampForTracking);

	useEffect(() => {
		if (!featureTogglesModel.isOn('MONETATE_DISABLED') && monetateEnabled) {
			window.monetateT = new Date().getTime();
			// WIT-16225: monetate tag needs to wait for adobe launch to set the pageType
			/* eslint-disable */
			setTimeout(() => {
				var p = document.location.protocol;
				if (p == "http:" || p == "https:") {
					var m = document.createElement('script'); m.type = 'text/javascript'; m.async = true; m.src = `//se.monetate.net/js/2/a-d18aefa0/${monetateTagType}/roomandboard.com/custom.js`;
					var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(m, s);
				}
			}, 2500)
			/* eslint-enable */
		}

		if (affirmEnabled) {
			window._affirm_config = {
				public_api_key: affirmPublicApiKey,
				script: affirmScriptUrl
			};
			/* eslint-disable */
			(function (l, g, m, e, a, f, b) {
				var d, c = l[m] || {}, h = document.createElement(f), n = document.getElementsByTagName(f)[0],
					k = function (a, b, c) { return function () { a[b]._.push([c, arguments]) } }; c[e] = k(c, e, "set"); d = c[e]; c[a] = {}; c[a]._ = [];
				d._ = []; c[a][b] = k(c, a, b); a = 0; for (b = "set add save post open empty reset on off trigger ready setProduct"
					.split(" "); a < b.length; a++)d[b[a]] = k(c, e, b[a]); a = 0; for (b = ["get", "token", "url", "items"]; a < b.length; a++)d[b[a]
					] = function () { }; h.async = !0; h.src = g[f]; n.parentNode.insertBefore(h, n); delete g[f]; d(g); l[m] = c
			})(window,
				_affirm_config, "affirm", "checkout", "ui", "script", "ready");
			/* eslint-enable */
		}

		window.eventDataLayer = window.eventDataLayer || [];
		window.nextPagesEventDataLayer = [];

		if (typeof window.routeChangeStarted === 'undefined') {
			window.routeChangeStarted = false;
		}

		VisitorEventModelFactory.create(globalDynamicStore);

		if (!window.searchTracking) {
			window.searchTracking = SearchEventsTrackingStoreFactory.create(featureTogglesModel);
		}

		const tempDynamicLoadedCssModulesFix = () => {
			const allStyleElems = document.querySelectorAll('link[rel="preload"]');
			allStyleElems.forEach((elem) => {
				elem.setAttribute('rel', 'stylesheet');
			});
		};

		const handleRouteChangeStart = (newRouteUrl, { shallow }) => {
			tempDynamicLoadedCssModulesFix();
			if (!shallow) {
				//clear out the personalization model on route change so we get fresh personalization information for new route
				personalizationStore.setDecisions({});
				window.routeChangeStarted = true;
				window.upcomingPagesEventDataLayer = [];
				window.eventDataLayer.push({ event: 'routeChangeStart' });
			}
		};

		const handleRouteChangeComplete = (newRouteUrl, { shallow }) => {
			tempDynamicLoadedCssModulesFix();
			if (!shallow) {
				console.info('resetting data layer');
				window.routeChangeStarted = false;
				// console.info(JSON.parse(JSON.stringify(window.eventDataLayer)));
				window.eventDataLayer.length = 0;
				VisitorEventModelFactory.create(globalDynamicStore);
				globalDynamicStore.fetchData();

				if (window.upcomingPagesEventDataLayer.length) {
					const existingItems = window.eventDataLayer.splice(0, window.eventDataLayer.length);
					window.eventDataLayer.push(...window.upcomingPagesEventDataLayer, ...existingItems);
					console.info('adding new pages items to eventDataLayer', window.upcomingPagesEventDataLayer);
					window.upcomingPagesEventDataLayer = [];
				}
				window.eventDataLayer.push({ event: 'routeChangeComplete' });
			}
		};

		routerEvents.on('routeChangeStart', handleRouteChangeStart);

		routerEvents.on('routeChangeComplete', handleRouteChangeComplete);

		return () => {
			router.events.off('routeChangeStart', handleRouteChangeStart);

			router.events.off('routeChangeComplete', handleRouteChangeComplete);
		};
	}, []);

	return (
		<>
			<NextHead>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge;chrome=1" />

				<title>{prefixedTitle}</title>
				<link rel="canonical" href={`${sitePath}${canonicalUrl || asPathWithoutParams}`} />
				{provideRelAlternate && <link rel="alternate" media="only screen and (max-width: 640px)" href={`${sitePath}${rootRelativeMobileSitePath}${asPath}`} />}

				{
					(noRobots || noRobotsProp) &&
					<meta name="robots" content="noindex, nofollow" />
				}

				{
					isEngageTraining && (
						<>
							<meta name="msapplication-config" content="/nextjs-static/img/ico/favicons/engage-training-browserconfig.xml" />
							<meta name="theme-color" content="#ffffff" />
							<link rel="manifest" href="/nextjs-static/img/ico/favicons/engage-training-manifest.json" />
							<link rel="apple-touch-icon" sizes="180x180" href={`${s7ImagePath}/engage-training-apple-touch-icon`} />
							<link rel="icon" type="image/png" sizes="32x32" href={`${s7ImagePath}/engage-training-favicon-32x32`} />
							<link rel="icon" type="image/png" sizes="16x16" href={`${s7ImagePath}/engage-training-favicon-16x16`} />
							<link rel="mask-icon" color="#5bbad5" href={`${s7ContentPath}/engage-training-safari-pinned-tab`} />
							<link rel="shortcut icon" href="/img/ico/favicon/engagetraining/favicon.ico" />
						</>
					)
				}

				{
					isEngage && (
						<>
							<meta name="msapplication-config" content="/nextjs-static/img/ico/favicons/engage-browserconfig.xml" />
							<meta name="theme-color" content="#ffffff" />
							<link rel="manifest" href="/nextjs-static/img/ico/favicons/engage-manifest.json" />
							<link rel="apple-touch-icon" sizes="180x180" href={`${s7ImagePath}/engage-apple-touch-icon`} />
							<link rel="icon" type="image/png" sizes="32x32" href={`${s7ImagePath}/engage-favicon-32x32`} />
							<link rel="icon" type="image/png" sizes="16x16" href={`${s7ImagePath}/engage-favicon-16x16`} />
							<link rel="mask-icon" color="#5bbad5" href={`${s7ContentPath}/engage-safari-pinned-tab`} />
							<link rel="shortcut icon" href="/img/ico/favicon/engage/favicon.ico" />
						</>
					)
				}

				{
					!isEngageTraining && !isEngage && (
						<>
							<meta name="application-name" content="Room & Board" />
							<meta name="msapplication-config" content="/nextjs-static/img/ico/favicons/web-browserconfig.xml" />
							<link rel="apple-touch-icon" sizes="60x60" href={`${s7ImagePath}/web-apple-touch-icon-60x60`} />
							<link rel="apple-touch-icon" sizes="76x76" href={`${s7ImagePath}/web-apple-touch-icon-76x76`} />
							<link rel="apple-touch-icon" sizes="120x120" href={`${s7ImagePath}/web-apple-touch-icon-120x120`} />
							<link rel="apple-touch-icon" sizes="152x152" href={`${s7ImagePath}/web-apple-touch-icon-152x152`} />
							<link rel="apple-touch-icon" sizes="180x180" href={`${s7ImagePath}/web-apple-touch-icon-180x180`} />
							<link rel="shortcut icon" href="/img/ico/favicon/favicon.ico" />
						</>
					)
				}

				<meta name="copyright" content={`Copyright ${currentYear} Room &amp; Board, Inc. All Rights Reserved.`} />
				<meta name="description" content={description} />
				<meta name="keywords" content={keywords} />

				{/* Bing webmaster tag */}
				<meta name="msvalidate.01" content="45B9E9B2F38529501DA7AE28AC6CF6EE" />

				{/* Disable Edge browser from formatting phone numbers */}
				<meta name="format-detection" content="telephone=no" />

				<meta property="og:description" content={description} />
				<meta property="og:image" content={cleanImage} />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta property="og:site_name" content="Room & Board" />
				<meta property="og:title" content={title} />
				<meta property="og:type" content={openGraphType} />
				<meta property="og:url" content={url} />

				{/* Reflektion */}
				<meta property="rfk:image" content={cleanImage} />
				<meta property="rfk:source" content="website" />
				{
					title && (
						<meta property="rfk:title" content={prefixedTitle} />
					)
				}
				<meta property="rfk:url" content={url} />

				{/* Twitter */}
				<meta name="twitter:site" content={url} />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:image" content={cleanImage} />

				<script
					type="application/ld+json"
					dangerouslySetInnerHTML={{
						__html: `
							{
								"@context": "http://schema.org",
								"@type": "WebSite",
								"url": "https://www.roomandboard.com/",
								"potentialAction": {
									"@type": "SearchAction",
									"target": "https://www.roomandboard.com/search/?query={search_term_string}",
									"query-input": "required name=search_term_string"
								}
							}
						`,
					}}
				/>

				{additionalScriptTags}
			</NextHead>
			<PageViewEvent prefixedTitle={prefixedTitle} type={type} errorStatusCode={errorStatusCode} />
		</>
	);
};

export default withRouter(Head); // eslint-disable-line import/no-default-export
